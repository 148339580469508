import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { UX, UX2 } from '@wsb/guac-widget-core';
import dataAids from '../dataAids';
import dimensions from '../../common/constants/dimensions';
import supportedLanguages from '../../common/constants/languages';
const { MatchMedia } = UX;
const defaultRestaurantId = 347401;

class OpenTableContent extends Component {
  static get propTypes() {
    return {
      viewDevice: PropTypes.string.isRequired,
      restaurantId: PropTypes.string.isRequired,
      section: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      publishMode: PropTypes.bool.isRequired,
      editMode: PropTypes.bool,
      addMode: PropTypes.bool,
      locale: PropTypes.string,
      layoutMode: PropTypes.bool
    };
  }

  constructor(props) {
    super(props);
    this.handleMatchMedia = this.handleMatchMedia.bind(this);
    this.state = {
      isMobile: false
    };
  }

  handleMatchMedia(data) {
    const { publishMode, viewDevice } = this.props;
    const isMobile = publishMode ? data.size === 'xs' : /mobile/i.test(viewDevice);
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  }

  parseLanguage() {
    const { locale } = this.props;
    if (locale && locale.length && locale.length > 1) {
      const lang = locale.substr(0, 2);
      return supportedLanguages.findIndex(l => l === lang) >= 0 ? lang : 'en';
    }
    return 'en';
  }

  getIframeProps() {
    const { isMobile } = this.state;
    const { restaurantId, layoutMode, editMode, addMode } = this.props;
    const width = isMobile ? dimensions.mobileWidth : dimensions.desktopWidth;
    const height = isMobile ? dimensions.mobileHeight : dimensions.desktopHeight;
    const theme = isMobile ? 'standard' : 'wide';
    const lang = this.parseLanguage();
    const rid = restaurantId || (layoutMode && defaultRestaurantId);
    // eslint-disable-next-line max-len
    const src = `//www.opentable.com/widget/reservation/canvas?rid=${rid}&domain=com&type=standard&theme=${theme}&lang=${lang}&overlay=false&insideiframe=true`;
    return {
      width,
      height,
      src: typeof window !== 'undefined' ? src : null,
      frameBorder: '0',
      scrolling: 'no',
      style: { pointerEvents: editMode || addMode ? 'none' : 'auto' }
    };
  }

  renderOpenTable() {
    const containerStyles = this.state.isMobile
      ? {}
      : { style: { height: dimensions.desktopHeight } };
    return (
      <UX2.Element.Block { ...containerStyles }>
        <iframe
          title='open table frame'
          data-aid={ dataAids.RESERVATION_IFRAME_RENDERED }
          { ...this.getIframeProps() }
        />
        <MatchMedia onChange={ this.handleMatchMedia } />
      </UX2.Element.Block>
    );
  }

  render() {
    return (
      <UX2.Element.Block data-route='restaurantId' style={{ textAlign: 'center' }}>
        { this.renderOpenTable() }
      </UX2.Element.Block>
    );
  }
}

export default OpenTableContent;
